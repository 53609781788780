import { Link } from 'react-router-dom';
import logo_light from '../../assets/images/logo-light.png';
import React from 'react'

export default function EmailConfirmation() {
    return (
        <>
            <div style={{marginTop: 50+'px'}}>
                <table cellPadding="0" cellSpacing="0" style={{fontFamily: 'Nunito, sans-serif', fontSize: 15+'px', fontWeight: 400, maxWidth: 600 + 'px',  border: 'none', margin: '0 auto', borderRadius: 6+ 'px', overflow: 'hidden', backgroundColor: '#fff', boxShadow: '0 0 3px rgba(60, 72, 88, 0.15)' }}>
                <thead>
                    <tr style={{backgroundColor: "#4f46e5", padding: "3px 0", border: "none",  height: "68px",textAlign: "center", color:" #fff", fontSize: "24px", }}>
                        <th scope="col"><img src={logo_light} alt="" style={{margin:"0px auto"}}/></th>
                    </tr>
                </thead>

                    <tbody>
                        <tr>
                            <td style={{padding: '48px 24px 0', color: '#161c2d', fontSize: '18px', fontWeight: 600}}>
                                Hello, Harry
                            </td>
                        </tr>
                        <tr>
                            <td style={{padding: '15px 24px 15px',  color: '#8492a6'}}>
                                Thanks for creating an Techwind account. To continue, please confirm your email address by clicking the button below :
                            </td>
                        </tr>

                        <tr>
                            <td style={{padding: '15px 24px'}}>
                                <Link  style={{padding: '8px 20px',  outline: 'none', textDecoration: 'none', fontSize: '16px', letterSpacing: 0.5+ 'px', transition: 'all 0.3s', fontWeight: 600,  borderRadius: 6+ 'px', backgroundColor: '#4f46e5', border: '1px solid #4f46e5', color: '#ffffff'}}>Confirm Email Address</Link>
                            </td>
                        </tr>

                        <tr>
                            <td style={{padding: '15px 24px 0', color: '#8492a6'}}>
                                This link will be active for 30 min from the time this email was sent.
                            </td>
                        </tr>

                        <tr>
                            <td style={{padding: '15px 24px 15px',  color: '#8492a6'}}>
                                Techwind <br /> Support Team
                            </td>
                        </tr>

                        <tr>
                            <td style={{padding: '16px 8px', color: '#8492a6', backgroundColor: '#f8f9fc',  textAlign: 'center'}}>
                                © {new Date().getFullYear()} Techwind.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </>
    )
}

import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";
import PageServices from "./pages/company/pageServices";
import IndexModernSaas from "./pages/index/indexModernSaas";
import Helpcenter from "./pages/helpcenter/helpcenter";
import HelpcenterSupport from "./pages/helpcenter/helpcenterSupport";
import HelpcenterFaqs from "./pages/helpcenter/helpcenterFaqs";
import EmailPasswordReset from "./pages/email-template/emailPasswordReset";
import EmailInvoice from "./pages/email-template/emailInvoice";
import EmailConfirmation from "./pages/email-template/emailConfirmation";
import EmailAlert from "./pages/email-template/emailAlert";
import Documentation from "./pages/documentation";
import ContactOne from "./pages/contact/contactOne";
import HelpcenterGuides from "./pages/helpcenter/helpcenterGuides";
import Switch from "./component/Switch";

export default function App() {
  return (
    <BrowserRouter>
      <Switch />
      <Routes>
        <Route path="/" element={<Navigate to="/index" />} />
        <Route exact path="/index" element={<IndexModernSaas />} />

        <Route exact path="/page-services" element={<PageServices />} />

        <Route exact path="/helpcenter-overview" element={<Helpcenter />} />
        <Route
          exact
          path="/helpcenter-support"
          element={<HelpcenterSupport />}
        />
        <Route exact path="/helpcenter-faqs" element={<HelpcenterFaqs />} />
        <Route exact path="/helpcenter-guides" element={<HelpcenterGuides />} />
        <Route
          exact
          path="/email-password-reset"
          element={<EmailPasswordReset />}
        />
        <Route exact path="/email-invoice" element={<EmailInvoice />} />
        <Route
          exact
          path="/email-confirmation"
          element={<EmailConfirmation />}
        />
        <Route exact path="/email-alert" element={<EmailAlert />} />
        <Route exact path="/documentation" element={<Documentation />} />
        <Route exact path="/contact-one" element={<ContactOne />} />
      </Routes>
    </BrowserRouter>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";

import logo_icon_40 from "../../assets/images/logo-icon-40.png";
import classic02 from "../../assets/images/saas/classic02.png";
import classic03 from "../../assets/images/saas/classic03.png";
import dashboardvector from "../../assets/images/saas/dashboard-vector.png";
import imageP from "../../assets/images/blog/aws-sample.png";
import imageG from "../../assets/images/blog/blog-github.png";

import Navbar from "../../component/Navbar/navbar";
import CookieModal from "../../component/cookieModal";

import { Monitor } from "feather-icons-react/build/IconComponents";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";
import CountUp from "react-countup";
import SmallFooter from "../../component/Footer/smallFooter";

export default function IndexModernSaas() {
  const [isOpen, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const accordionData = [
    {
      title: "What is Data Dialogue?",
      content:
        "Data Dialogue is an AI-driven platform designed to simplify and streamline complex data interactions for businesses. Through a combination of human consulting and advanced AI technology, we enable businesses to easily access and interpret their data.",
    },
    {
      title: "How does the Natural Language Query system work?",
      content:
        "Our Natural Language Query system allows users to pose questions in plain English, without needing to know SQL or any other query language. Our AI interprets the question, accesses the right database, and provides a clear answer or visual insight.",
    },
    {
      title: "Can Data Dialogue integrate with any cloud provider?",
      content:
        "Yes, Data Dialogue is designed for versatility. We seamlessly integrate with major cloud providers such as AWS, Azure, and Google Cloud, ensuring flexibility and scalability for businesses.",
    },
    {
      title: "Who can benefit from using Data Dialogue?",
      content:
        "Data Dialogue caters to a wide audience, including but not limited to: CEOs, CTOs, CIOs, IT professionals, data analysts, SME owners, tech startups, and marketing professionals. Essentially, anyone looking for efficient data management and insights can benefit from our platform.",
    },
  ];

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const blogData = [
    {
      image: imageP,
      title: "Merging OpenAI GPT-4 with AWS Glue Data Catalog",
      link: "https://datadialogue.medium.com/revolutionizing-data-queries-merging-openai-gpt-4-with-aws-glue-data-catalog-c0b7fd49facb",
      desc: "With the integration of generative AI and cloud services, it’s now possible to create intuitive chatbots that can communicate seamlessly with data catalogs. In this tutorial, we’ll walk you through building such a chatbot for AWS Glue Data Catalog using the sample provide from DataDialogue.ai",
    },
    {
      image: imageG,
      title: "Sample Code of OpenAI GPT4 + AWS IaC",
      link: "https://github.com/canivel/datadialogue-sample",
      desc: "This package allows you to deploy a Data Catalog in AWS Glue that interfaces with the OpenAI API through a Langchain Lambda function. The purpose is to execute SQL queries against your data catalog using an Athena-based connection. Additionally, an API Gateway is deployed that uses AWS Cognito for authentication.",
    },
  ];

  return (
    <>
      <Navbar />
      <section className="relative table w-full py-36 overflow-hidden bg-gradient-to-b to-transparent from-indigo-600/20 dark:from-indigo-600/40">
        <div className="container relative">
          <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-6">
              <div className="md:me-8">
                <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white relative">
                  Generative AI: The{" "}
                  <span className="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-indigo-600 relative inline-block">
                    <span className="relative text-white">Future</span>
                  </span>{" "}
                  of Data Insights
                </h4>
                <p className="text-slate-400 text-lg max-w-xl">
                  Say goodbye to technical jargons and complex queries. With
                  Data Dialogue, your data becomes a conversation, driven by AI
                  and tailored for clarity.
                </p>
                <div className="mt-8">
                  <span className="text-slate-400 font-medium">
                    <Link
                      to="/contact-one"
                      className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                    >
                      <i className="uil uil-chat align-middle me-2"></i> Want to
                      know more ? Let's have a Chat
                    </Link>
                  </span>
                </div>
              </div>
            </div>

            <div className="md:col-span-6">
              <div className="relative">
                <div className="relative rounded-xl overflow-hidden shadow-md dark:shadow-gray-800">
                  <div
                    className="w-full py-72 bg-slate-400 bg-[url('../../assets/images/saas/home.jpg')] bg-cover bg-no-repeat bg-top jarallax"
                    data-jarallax
                    data-speed="0.5"
                  ></div>
                </div>

                <div className="absolute flex justify-between items-center md:bottom-10 bottom-5 md:-start-16 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-60 m-3">
                  <div className="flex items-center">
                    <div className="flex items-center justify-center h-[65px] min-w-[65px] bg-indigo-600/5 text-indigo-600 text-center rounded-full me-3">
                      <Monitor className="h-6 w-6"></Monitor>
                    </div>
                    <div className="flex-1">
                      <h6 className="text-slate-400">How many sales?</h6>
                      <p className="text-xl font-bold">
                        <CountUp
                          className="counter-value"
                          end={4589}
                          start={2100}
                        />
                      </p>
                    </div>
                  </div>

                  <span className="text-green-600">
                    <i className="uil uil-chart-up"></i> 72%
                  </span>
                </div>

                <div className="absolute xl:top-20 top-40 xl:-end-20 lg:-end-10 -end-1 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-60 m-3">
                  <h5 className="text-xl font-semibold mb-3">
                    Just ask. No need for technical jargons or SQL knowledge.
                  </h5>
                  <div className="flex justify-between mt-3 mb-2">
                    <span className="text-slate-400">Data Synced</span>
                    <span className="text-slate-400">92%</span>
                  </div>
                  <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                    <div
                      className="bg-indigo-600 h-[6px] rounded-full"
                      style={{ width: "92%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:pb-24 pb-16 overflow-hidden">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <div className="flex items-center justify-center rounded-full mx-auto bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 w-[110px] h-[110px]">
              <img src={logo_icon_40} className="block mx-auto" alt="" />
            </div>
            <h3 className="mb-6 mt-8 md:text-4xl text-3xl md:leading-normal leading-normal font-bold">
              Welcome to Data Dialogue - Your AI-Driven Data Partner! <br />
              <span className="after:absolute after:end-0  after:start-0  after:bottom-1 after:lg:h-3 after:h-2 after:w-auto after:rounded-md after:bg-indigo-600/30 relative text-indigo-600">
                Navigate the complex world of data with the ease of a
                conversation.
              </span>
            </h3>
          </div>

          <div className="grid mt-8">
            <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800">
              <video>
                <source src="/short-data-dialogue.mp4" type="video/mp4" />
              </video>

              <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                <Link
                  to="#"
                  onClick={() => setOpen(true)}
                  data-type="youtube"
                  data-id="c7-FTqQBDKs"
                  className="lightbox lg:h-20 h-16 lg:w-20 w-16 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 hover:bg-indigo-600 dark:hover:bg-indigo-600 text-indigo-600 hover:text-white duration-500 ease-in-out mx-auto"
                >
                  <i className="mdi mdi-play inline-flex items-center justify-center text-3xl"></i>
                </Link>
              </div>
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="c7-FTqQBDKs"
            onClose={() => setOpen(false)}
          />
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Natural Language Queries
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Data Dialogue's natural language query system is about breaking
              barriers. By creating an intuitive interaction platform, we ensure
              that everyone, irrespective of their technical prowess, can delve
              deep into data effortlessly.
            </p>
          </div>

          <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
            <div className="relative">
              <img
                src={classic02}
                className="rounded-lg shadow-md dark:shadow-gray-800"
                alt=""
              />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 dark:bg-indigo-600/20 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
            </div>

            <div className="lg:ms-8">
              <h4 className="mb-4 text-2xl leading-normal font-medium">
                Intuitive Interaction <br /> for real usecases
              </h4>
              <p className="text-slate-400">
                Forget about complex codes. Just type or speak your question in
                plain English and let our system do the heavy lifting.
              </p>
              <ul className="list-none space-x-1 text-slate-400 mt-4">
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                  User-Friendly Interface
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                  Contextual Understanding
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                  Automated SQL Generation
                </li>
              </ul>

              <div className="mt-4">
                <Link
                  to="/page-aboutus"
                  className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                >
                  Find Out More{" "}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div className="relative order-1 md:order-2">
              <img
                src={classic03}
                className="rounded-lg shadow-md dark:shadow-gray-800"
                alt=""
              />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 dark:bg-indigo-600/20 bottom-0 end-0 rotate-45 -z-1 rounded-3xl"></div>
            </div>

            <div className="lg:me-8 order-2 md:order-1">
              <h4 className="mb-4 text-2xl leading-normal font-medium">
                Database Consolidation
              </h4>
              <p className="text-slate-400">
                Data Dialogue's Data Lake Foundation is the bedrock upon which
                modern data architectures stand. It’s not just about storage;
                it’s about creating a cohesive ecosystem. By amalgamating the
                vastness of a data lake with the agility of a data mesh, we
                provide a playground for data exploration and AI-driven
                discoveries. This foundation ensures that your data is not just
                stored but is primed, ready, and waiting to deliver insights."
              </p>
              <ul className="list-none space-x-1 text-slate-400 mt-4">
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                  Unified Data Reservoir
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                  Data Mesh Architecture
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                  Foundation for AI
                </li>
              </ul>

              <div className="mt-4">
                <Link
                  to="/page-aboutus"
                  className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                >
                  Find Out More{" "}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <section className="relative md:py-24 py-16">
          <div className="container relative md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                Blog and Tutorials
              </h3>
            </div>
          </div>
          <div className="container relative">
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
              {blogData.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden"
                  >
                    <img src={item.image} alt="" />

                    <div className="content p-6">
                      <Link
                        to={item.link}
                        className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out"
                      >
                        {item.title}
                      </Link>
                      <p className="text-slate-400 mt-3">{item.desc}</p>

                      <div className="mt-4">
                        <Link
                          to={item.link}
                          className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                        >
                          Read More <i className="uil uil-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Frequently Asked Questions
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Diving into the world of Data Dialogue? We've compiled a quick set
              of FAQs to address your initial queries and set the stage for a
              seamless data journey.
            </p>
          </div>

          <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-8 gap-[30px]">
            <div className="md:col-span-6">
              <div className="relative">
                <div className="relative rounded-xl overflow-hidden shadow-md dark:shadow-gray-800">
                  <div
                    className="w-full py-72 bg-slate-400 bg-[url('../../assets/images/saas/cta.jpg')] bg-no-repeat bg-top bg-cover jarallax"
                    data-jarallax
                    data-speed="0.5"
                  ></div>
                </div>
              </div>
            </div>

            <div className="md:col-span-6">
              <div id="accordion-collapse">
                {accordionData.map((item, index) => (
                  <div
                    key={index}
                    className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
                  >
                    <h2
                      className="text-base font-semibold"
                      id="accordion-collapse-heading-1"
                    >
                      <button
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        className={`flex justify-between items-center p-5 w-full font-medium text-start ${
                          activeIndex === index
                            ? "bg-gray-50 dark:bg-slate-800 text-indigo-600"
                            : ""
                        }`}
                        data-accordion-target="#accordion-collapse-body-1"
                        aria-expanded="true"
                        aria-controls="accordion-collapse-body-1"
                      >
                        <span>{item.title}</span>
                        <svg
                          data-accordion-icon
                          className={`${
                            activeIndex === index ? "rotate-180" : "rotate-270"
                          } w-4 h-4 shrink-01`}
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </h2>
                    {activeIndex === index && (
                      <div>
                        <div className="p-5">
                          <p className="text-slate-400 dark:text-gray-400">
                            {item.content}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Have Question ? Get in touch!
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Our support team is always on standby. Whether it's a technical
              glitch or a query, we're here to assist.
            </p>

            <div className="mt-6">
              <Link
                to="/contact-one"
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
              >
                <i className="uil uil-phone align-middle me-2"></i> Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>

      <SmallFooter />
      <CookieModal />
    </>
  );
}

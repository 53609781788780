import React from "react";
import { Link } from "react-router-dom";
import logo_light from "../../assets/images/logo-light.png";
export default function SmallFooter() {
  return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
      <div className="py-[30px] px-0 border-t border-slate-800">
        <div className="container relative text-center">
          <div className="grid lg:grid-cols-12 md:grid-cols-3 grid-cols-1 items-center">
            <div className="lg:col-span-2 md:text-start text-center">
              <Link to="/#" className="text-[22px] focus:outline-none">
                <img
                  src={logo_light}
                  className="mx-auto md:me-auto md:ms-0"
                  alt=""
                />
              </Link>
            </div>

            <div className="lg:col-span-6 text-center mt-6 md:mt-0">
              <p className="mb-0">
                © {new Date().getFullYear()}{" "}
                <Link
                  to="/https://canivel.com/"
                  target="_blank"
                  className="text-reset"
                >
                  Canivel LLC.
                </Link>
              </p>
            </div>

            <ul className="lg:col-span-4 list-none space-x-1 md:text-end text-center mt-6 md:mt-0">
              <li className="inline">
                <Link
                  to="http://linkedin.com/company/data-dialogue-ai"
                  target="_blank"
                  className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                >
                  <i className="uil uil-linkedin" title="Linkedin"></i>
                </Link>
              </li>
              <li className="inline">
                <Link
                  to="mailto:contact@datadialogue.ai"
                  className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                >
                  <i
                    className="uil uil-envelope align-middle"
                    title="email"
                  ></i>
                </Link>
              </li>
              <li className="inline">
                <Link
                  to="https://forms.gle/dDVxpYLDzgsQ3HR79"
                  target="_blank"
                  className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                >
                  <i
                    className="uil uil-file align-middle"
                    title="customization"
                  ></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
